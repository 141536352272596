import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Box } from "@chakra-ui/react"

import DiscoverCountries from "../components/DiscoverCountries"
import GetInspired from "../components/GetInspired"
import OurExperts from "../components/OurExperts"
import TopSailingTips from "../components/TopSailingTips"
import Trust from "../components/Trust"
import Layout from "../components/Layout"
import Mullet from "../components/Mullet"
import SEO from "../components/SEO"
import SignUp from "../components/SignUp"

const IndexPage = ({ data, location }) => {
  const countryM = location && location.search && location.search.match(/country=(\w+)/)
  const country = countryM && countryM[1]
  return (
    <Layout
      heroCopy={data.prismicHomepage.data.hero_copy}
      heroImage={data.prismicHomepage.data.homepage_hero}
      country={country}
    >
      <Box sx={{ h2: { pb: 16 } }}>
        <SEO title="Home" node={data.prismicHomepage} />
        <Trust copy={data.prismicHomepage.data} />
        <GetInspired copy={data.prismicHomepage.data} />
        <DiscoverCountries copy={data.prismicHomepage.data} />
        <TopSailingTips copy={data.prismicHomepage.data} />
        <Mullet article={data.prismicHomepage} title={data.prismicHomepage.data.mullet_title} />
        <OurExperts experts={data.allPrismicAuthor.nodes} copy={data.prismicHomepage.data} />
        <SignUp copy={data.prismicHomepage.data} />
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query HomepageQuery {
    allPrismicAuthor(limit: 3, filter: { data: { active: { eq: true } } }) {
      nodes {
        uid
        url
        data {
          avatar {
            gatsbyImageData(width: 200, height: 200)
          }
          name
        }
      }
    }
    prismicHomepage {
      data {
        meta_title
        meta_description
        meta_image {
          gatsbyImageData(width: 1200, height: 630, layout: FIXED)
        }
        discover_countries_header
        get_inspired_all_blogs
        get_inspired_header
        hero_copy
        nav_1
        nav_2
        trust_1_header
        trust_1_copy {
          richText
        }
        trust_2_header
        trust_2_copy {
          richText
        }
        trust_3_header
        trust_3_copy {
          richText
        }
        our_experts_header
        sign_up_top_button
        sign_up_top_copy
        sign_up_privacy
        top_sailing_tips_all_tips
        top_sailing_tips_header
        homepage_hero {
          gatsbyImageData(width: 2560, height: 700)
        }
        mullet_title
        body {
          ... on PrismicHomepageDataBodyRichText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(width: 1200, height: 500)
              }
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default IndexPage
