import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from "@chakra-ui/react"

import Section from "./Section"
import Link from "./Link"
import Card from "./Card"

const TopSailingTips = ({ copy: { top_sailing_tips_all_tips, top_sailing_tips_header } }) => {
  const data = useStaticQuery(graphql`
    query TopSailingTips {
      allPrismicArticle(
        filter: {
          uid: {
            in: [
              "parts-of-a-ship"
              "skippered-vs-bareboat"
              "nautical-terms"
              "sailing-knots"
              "route-planning"
              "catamaran-monohull"
            ]
          }
        }
        sort: { fields: data___sort_order }
      ) {
        nodes {
          url
          uid
          data {
            title
            heroimage {
              alt
              gatsbyImageData(width: 350, height: 270)
            }
          }
        }
      }
    }
  `)
  const articles = data.allPrismicArticle.nodes

  return (
    <Section py={16} highlighted headline={top_sailing_tips_header}>
      <Grid
        sx={{
          gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
          gridColumnGap: [8, 12, 12],
          gridRowGap: [6, 12],
        }}
      >
        {articles.map((a) => (
          <Card
            key={a.uid}
            image={a.data.heroimage}
            imgProps={{
              h: ["253px", "233px", "270px"],
            }}
            to={a.url}
            title={a.data.title}
          />
        ))}
        <Link
          to="/sailing-tips"
          sx={{
            gridColumn: "1 / -1",
            textAlign: "center",
            variant: "links.primary",
          }}
        >
          {top_sailing_tips_all_tips}
        </Link>
      </Grid>
    </Section>
  )
}

TopSailingTips.propTypes = {
  copy: PropTypes.shape({
    top_sailing_tips_header: PropTypes.string.isRequired,
    top_sailing_tips_all_tips: PropTypes.string.isRequired,
  }).isRequired,
}

export default TopSailingTips
