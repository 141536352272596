import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GridItem, Grid, Text } from "@chakra-ui/react"

import Section from "./Section"
import Link from "./Link"
import Card from "./Card"

const GetInspired = ({ copy: { get_inspired_header, get_inspired_all_blogs } }) => {
  const data = useStaticQuery(graphql`
    query GetInspiredQuery {
      allPrismicArticle(
        filter: { uid: { in: ["dubrovnik-itinerary", "kornati-itinerary", "split-itinerary", "hvar-sailing"] } }
        sort: { fields: data___sort_order }
      ) {
        nodes {
          url
          data {
            title
            heroimage {
              alt
              gatsbyImageData(width: 570, height: 532)
            }
          }
        }
      }
    }
  `)
  const articles = data.allPrismicArticle.nodes

  return (
    <Section py={16} highlighted headline={get_inspired_header}>
      <Grid templateColumns={["1fr", "repeat(4, 1fr)"]} autoRows={["auto", "261px", "auto"]} gap={[4, 6, 8]}>
        <GridItem colStart={[1, 1]} colSpan={[1, 2]} rowStart={[null, 1]} rowSpan={[2, 2]}>
          <Card
            image={articles[0].data.heroimage}
            imgProps={{
              h: ["180px", "430px", "532px"],
            }}
            to={articles[0].url}
            title={articles[0].data.title}
          />
        </GridItem>

        <GridItem colStart={[1, 3]} colSpan={[1, 1]} rowStart={[null, 1]} rowSpan={[1, 1]}>
          <Card
            image={articles[1].data.heroimage}
            imgProps={{
              h: ["180px", "162px", "196px"],
            }}
            to={articles[1].url}
            title={articles[1].data.title}
          />
        </GridItem>

        <GridItem colStart={[1, 4]} colSpan={[1, 1]} rowStart={[null, 1]} rowSpan={[1, 1]}>
          <Card
            image={articles[2].data.heroimage}
            imgProps={{
              h: ["180px", "162px", "196px"],
            }}
            to={articles[2].url}
            title={articles[2].data.title}
          />
        </GridItem>
        <GridItem colStart={[1, 3]} colSpan={[1, 2]} rowStart={[null, 2]} rowSpan={[1, 1]} alignSelf="end">
          <Card
            image={articles[3].data.heroimage}
            imgProps={{
              h: ["180px", "162px", "196px"],
            }}
            to={articles[3].url}
            title={articles[3].data.title}
          />
        </GridItem>
      </Grid>
      <Text textAlign="center" mt={12}>
        <Link
          to="/get-inspired"
          sx={{
            gridColumn: "1 / -1",
            variant: "links.primary",
          }}
        >
          {get_inspired_all_blogs}
        </Link>
      </Text>
    </Section>
  )
}

GetInspired.propTypes = {
  copy: PropTypes.shape({
    get_inspired_header: PropTypes.string.isRequired,
    get_inspired_all_blogs: PropTypes.string.isRequired,
  }).isRequired,
}

export default GetInspired
