import React from "react"
import { Grid } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Section from "./Section"
import Card from "./Card"

const DiscoverCountries = ({ copy: { discover_countries_header } }) => {
  const data = useStaticQuery(graphql`
    query GetCountriesQuery {
      allPrismicArticle(filter: { uid: { in: ["croatia", "greece", "italy"] } }, sort: { fields: data___sort_order }) {
        nodes {
          url
          data {
            title
            heroimage {
              alt
              gatsbyImageData(width: 570, height: 532)
            }
          }
        }
      }
    }
  `)
  const countries = data.allPrismicArticle.nodes
  return (
    <Section my={16} headline={discover_countries_header}>
      <Grid
        sx={{
          gridTemplateColumns: ["repeat(3, 85%)", "repeat(3, minmax(150px, 1fr))"],
          gridColumnGap: [4, 6, 8],
          overflowX: ["scroll", "hidden"],
          scrollSnapType: "x mandatory",
        }}
      >
        {countries.map((country) => (
          <Card
            key={country.url}
            image={country.data.heroimage}
            imgProps={{
              h: ["249px", "326px", "400px"],
            }}
            to={country.url}
            title={country.data.title.includes("Italy") ? "Italy" : country.data.title}
            sx={{
              scrollSnapAlign: "center",
              h3: {
                textAlign: "center",
              },
            }}
          />
        ))}
      </Grid>
    </Section>
  )
}

DiscoverCountries.propTypes = {
  copy: PropTypes.shape({
    discover_countries_header: PropTypes.string.isRequired,
  }).isRequired,
}

export default DiscoverCountries
