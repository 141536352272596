import React from "react"
import { Flex, VStack, Heading, chakra } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"

import Section from "./Section"
import { Quality, TrustHeart, TrustInstagram } from "./Icons"

const CenteredRichText = chakra(RichText, {
  baseStyle: {
    p: {
      maxW: "300px",
      m: 0,
      textAlign: "center",
      mb: ["16", null],
    },
    a: {
      _hover: {
        textDecoration: "underline",
      },
    },
  },
})

const TrustHeading = chakra(Heading, {
  baseStyle: {
    fontFamily: "body",
    color: "primary",
    fontSize: "2xl",
    mt: "1rem !important",
    mb: "0.75rem !important",
  },
})

const Trust = ({
  copy: { trust_1_header, trust_1_copy, trust_2_header, trust_2_copy, trust_3_header, trust_3_copy },
}) => (
  <Section my={16}>
    <Flex justify="space-around" direction={["column", "row"]}>
      <VStack>
        <Quality sx={{ color: "primary", fontSize: 3 }} />
        <TrustHeading as="h3">{trust_1_header}</TrustHeading>
        <CenteredRichText Component="div" render={trust_1_copy.richText} />
      </VStack>
      <VStack>
        <TrustHeart sx={{ color: "primary", fontSize: 3 }} />
        <TrustHeading as="h3">{trust_2_header}</TrustHeading>
        <CenteredRichText Component="div" render={trust_2_copy.richText} />
      </VStack>
      <VStack>
        <chakra.a lineHeight={0} href="https://www.instagram.com/highsails_/">
          <TrustInstagram sx={{ color: "primary", fontSize: 3 }} />
        </chakra.a>
        <TrustHeading as="h3">{trust_3_header}</TrustHeading>
        <CenteredRichText Component="div" render={trust_3_copy.richText} />
      </VStack>
    </Flex>
  </Section>
)

Trust.propTypes = {
  copy: PropTypes.shape({
    trust_1_header: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    trust_1_copy: PropTypes.object.isRequired,
    trust_2_header: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    trust_2_copy: PropTypes.object.isRequired,
    trust_3_header: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    trust_3_copy: PropTypes.object.isRequired,
  }).isRequired,
}

export default Trust
