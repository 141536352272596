import React from "react"
import PropTypes from "prop-types"

import Section from "./Section"
import SliceZone from "./SliceZone"

const Mullet = ({ article, title }) => {
  return (
    <Section
      sx={{ h2: { pb: "inherit" } }}
      my={16}
      headline={title}
      width={["100%", "90%", "70%"]}
      mx="auto"
    >
      <SliceZone allSlices={article.data.body} />
    </Section>
  )
}

Mullet.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  article: PropTypes.object.isRequired,
  title: PropTypes.string,
}

Mullet.defaultProps = {
  title: "",
}

export default Mullet
