import React from "react"
import PropTypes from "prop-types"
import { Button, chakra, Flex, Input } from "@chakra-ui/react"

import Section from "./Section"
import Link from "./Link"

const SignUp = ({
  copy: { sign_up_top_copy, sign_up_top_button, sign_up_privacy },
}) => (
  <Section my={16} headline={sign_up_top_copy}>
    <Flex sx={{ alignItems: "center", flexDirection: "column" }}>
      <chakra.form
        name="newsletter"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        sx={{
          display: "block",
          backgroundColor: "white",
          width: ["100%", "430px", "430px"],
        }}
      >
        <input type="hidden" name="bot-field" />
        <chakra.label htmlFor="email" sx={{ display: "none" }}>
          Email Address
        </chakra.label>
        <Input
          type="email"
          name="email"
          placeholder="Email Address"
          sx={{ mb: 6 }}
        />
        <Button type="submit" mb={6} sx={{ width: "100%" }}>
          {sign_up_top_button}
        </Button>
      </chakra.form>
      <p>
        <span>{sign_up_privacy}</span>
        <span>
          {" "}
          View our
          <Link to="/privacy-policy" sx={{ variant: "links.primary" }}>
            {" "}
            privacy policy.
          </Link>
        </span>
      </p>
    </Flex>
  </Section>
)

SignUp.propTypes = {
  copy: PropTypes.shape({
    sign_up_top_copy: PropTypes.string.isRequired,
    sign_up_top_button: PropTypes.string.isRequired,
  }).isRequired,
}

export default SignUp
