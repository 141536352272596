import React from "react"
import { Box, chakra, Heading } from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Link from "./Link"

const CImg = chakra(GatsbyImage, {
  baseStyle: {
    "&:hover picture": {
      opacity: ".85",
      transition: "opacity .1s ease-out",
    },
  },
})

const Card = ({ className, image, imgProps, to, title }) => {
  const card = (
    <Box>
      <CImg {...imgProps} image={getImage(image)} alt={image.alt} />
      <Heading as="h3" variant="h3" size="md" mt={4} noOfLines={2}>
        {title}
      </Heading>
    </Box>
  )

  if (to) {
    return (
      <Link className={className} to={to}>
        {card}
      </Link>
    )
  }
  return card
}

export default chakra(Card)
