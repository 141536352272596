import React from "react"
import { chakra, Stack, Text, VStack } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Section from "./Section"
import Link from "./Link"

const CImg = chakra(GatsbyImage)
const CLink = chakra(Link)

const OurExperts = ({ experts, copy: { our_experts_header } }) => {
  return (
    <Section py={16} headline={our_experts_header} highlighted>
      <Stack direction={["column", "row"]} spacing={[12, 40]} justify="center">
        {experts.map((e) => (
          <Link to={e.url} key={e.uid}>
            <VStack>
              <CImg
                sx={{
                  height: "150px",
                  width: "150px",
                  clipPath: "circle(50%)",
                  margin: "0 auto",
                }}
                image={getImage(e.data.avatar)}
                alt={`Profile image of ${e.data.name}`}
              />
              <chakra.h3>{e.data.name}</chakra.h3>
            </VStack>
          </Link>
        ))}
      </Stack>
      <Text textAlign="center" mt={12}>
        <CLink to="/authors">See all our experts</CLink>
      </Text>
    </Section>
  )
}

OurExperts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  experts: PropTypes.arrayOf(PropTypes.object).isRequired,
  copy: PropTypes.shape({
    our_experts_header: PropTypes.string.isRequired,
  }).isRequired,
}

export default OurExperts
